<template>
  <div class="toauditdialog">
       <!-- 点击审核弹窗 -->
      <el-dialog width="600px" :visible.sync="innerVisible" :close-on-click-modal="false" append-to-body>
        <div class="pass">
          <img src="@/assets/img/common/images/tonguolog.png" alt="">
          <h3>审核</h3>
          <p>通过后，用户支付金额{{pagedata.orderAmount}}元将原路退还给用户，确认审核通过吗?</p>
          <el-button type="danger" class="affirm-btn" @click="innerVisibleEvt()">确认</el-button>
          <el-button type="info" class="cancel-btn"  @click="innerreject()">驳回</el-button>
        </div>
      </el-dialog>
      <!-- 驳回弹窗 -->
        <el-dialog :close-on-click-modal="false" width="600px" :visible.sync="innerbouhui" append-to-body>
          <p class="wei">
            <span class="remark-title">驳回理由</span>
            <el-input type="textarea" :autosize="{ minRows: 8, maxRows: 8}"  maxlength="100"
            show-word-limit placeholder="请输入驳回理由~" v-model="textareas">
            </el-input>
            <el-button type="danger" class="submit-btn" @click="innerbouhuiE()">提交</el-button>
          </p>
        </el-dialog>
  </div>
</template>

<script>
import {
  $sp_refund, $sp_refundRejected,
} from '@/api/order';

export default {
  data() {
    return {
      flag: true,
      innerbouhui: false,
      textareas: '',
      innerVisible: false, // 删除弹窗
    };
  },
  name: 'Toauditdialog',
  props: ['pagedata'],
  methods: {
    // 点击审核的的确认
    innerVisibleEvt() {
      if (this.flag) {
        this.flag = false;
        const orderNo = this.pagedata.orderId;
        $sp_refund(`${orderNo}`).then(() => {
          this.innerVisible = false;
          this.$parent.getTableData();
        });
        setTimeout(() => {
          this.flag = true;
        }, 3000);
      }
    },
    // 点击驳回
    innerreject() {
      this.innerVisible = false;
      this.innerbouhui = true;
    },
    
    // 点击提交
    innerbouhuiE() {
      if (this.textareas) {
        const ids = {
          reason: this.textareas, orderNo: this.pagedata.orderId,
        };
        $sp_refundRejected(ids).then(() => {
          this.innerbouhui = false;
          this.textareas = '';
          this.$parent.getTableData();
        });
      } else {
        this.$message.error('请输入驳回理由');
      }
    },
  },
};

</script>

<style lang="scss" scoped>
.pass {
  text-align: center;
  h3 {
    font-size: 20px;
    color: black;
  }
  .affirm-btn {
    background-color: #ef3f46;
    margin: 50px 0 50px 10px;
    text-align: center;
    width: 100px;
  }
  .cancel-btn {
    background-color: #909399;
    width: 100px;
  }
}
</style>
