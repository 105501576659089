<template>
  <div class="rebate-order-container-page">
    <div class="order-choice-div">
      <el-button v-for="(item,index) in typeList" :key='index' @click="selectOrderType(index)" :type='active==index?"danger":""'>{{item}}
      </el-button>
    </div>
    <component :is="orderType"></component>
    <aside v-if="orderShow">
      <div class="screen-form-div">
        <el-form ref="form" :model="form" :inline="true" label-position="left" class="form">
          <el-form-item>
            <el-input type="number" class="inputStyle" v-model="form.orderId" maxlength="20" clearable placeholder="请输入订单编号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input type="number" class="inputStyle" v-model="form.phone" oninput="if(value.length > 11) value=value.slice(0, 11)"
              clearable placeholder="请输入充值号码"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input type="number" class="inputStyle" v-model="form.userPhone" oninput="if(value.length > 11) value=value.slice(0, 11)"
              clearable placeholder="请输入用户手机号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-select v-model="form.statusValue" placeholder="充值状态" clearable filterable>
              <el-option v-for="item in rechargeStatus" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="时间筛选" label-width="80">
            <el-date-picker v-model="form.screenTime" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
              @change="getTime">
            </el-date-picker>
          </el-form-item>
          <el-button type="primary" class="sure" @click="screen">筛选</el-button>
          <el-button type="info" class="sure" @click="clear">清空</el-button>
          <PrevReclick>
            <el-button type="primary" class="sure" @click="phoneOrderExport">导出</el-button>
          </PrevReclick>
        </el-form>
      </div>
      <div class="order-statistics-data-div flexbox flex-lr flex-align-center">
        <div class="order-statistics-all-num">订单总笔数：{{orderCount}}</div>
        <div class="order-statistics-user-payment">用户实付总金额：{{actualAmount}}</div>
      </div>
      <div class="order-list" id="order-list">
        <el-table :data="orderList" border stripe style="width: 100%;">
          <el-table-column prop="orderId" align="center" width="200" label="订单编号"></el-table-column>
          <el-table-column prop="phone" align="center" width="200" label="充值电话号码"></el-table-column>
          <el-table-column prop="phoneAttribution" align="center" width="200" label="运营商"></el-table-column>
          <el-table-column prop="changeAmount" align="center" width="120" label="用户充值面额"></el-table-column>
          <el-table-column prop="actualAmount" align="center" width="120" label="用户实付金额"></el-table-column>
          <el-table-column prop="payTime" align="center" width="200" label="支付时间">
            <template slot-scope="scope">
              <span>{{$formatDate(scope.row.payTime, 'yyyy-MM-dd HH:mm:ss' ) || '--'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="tel" align="center" width="200" label="用户手机号"></el-table-column>
          <el-table-column prop="payState" align="center" label="订单状态">
            <template slot-scope="scope">
              <span>{{scope.row.payState | orderState(scope.row.payState)}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="state" align="center" width="200" label="充值状态">
            <template slot-scope="scope">
              <el-select v-if="scope.row.state === 6" size="small" v-model="scope.row.selectedState" placeholder="" style="width:100px;"
                value-key="labelId" @change="passOrder(scope.row)">
                <el-option v-for="tag in rechargeStateList" :label="tag.labelName" :value="tag.labelId" :key="tag.labelId"></el-option>
              </el-select>
              <span v-else>{{scope.row.state | rechargeState(scope.row.state)}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="opreate" align="center" label="操作">
            <template slot-scope="scope">
              <span style="color:#ef3f46;cursor: pointer;"
                @click="openRefuseOrder(scope.row.orderId)">{{scope.row.refundState== 2?'-':'退款'}}</span>
            </template>
          </el-table-column>
          <TableEmpty slot="empty" />
        </el-table>
        <el-pagination class="mt10" background :page-count="pageCount" :page-size="pageSize" :current-page.sync="page"
          @current-change="getList"></el-pagination>
      </div>
      <Dialog ref="changePage">
        <div class="change-footer-button">
          <el-button type="info" class="sure" @click="cancel">取消</el-button>
          <el-button type="primary" class="sure" @click="sure">确定</el-button>
        </div>
      </Dialog>
      <Dialog ref="refuseOrder">
        <div class="change-footer-button">
          <el-button type="info" class="sure" @click="cancelRefuse">取消</el-button>
          <el-button type="primary" class="sure" @click="sureRefuse">确定</el-button>
        </div>
      </Dialog>
    </aside>
  </div>

</template>

<script>
import {
  $sg_getRebateStatistics, $sg_getRebateOrderList, $sg_passRebateOrder, $sg_passRefuse,
} from '@/api/order';
import Dialog from '@/components/common/dialog.vue';
import { exportFileSync } from '@base/utils';

const typeList = ['返利订单', '返利卡订单', '话费充值订单', '优惠券订单', '松鼠团购订单'];
export default {
  name: 'rebateOrder',
  components: {
    Dialog,
    'buy-rebate-order': () => import('./buyRebateOrder'),
    'buy-rebate-card-order': () => import('./buyRebateCardOrder'),
    'buy-coupon-order': () => import('./buycouponOrder'),
    'buy-ssGroup-order': () => import('./buyRebateSsOrder'),
  },
  data() {
    return {
      typeList,
      rechargeStatus: [
        { label: '全部', value: '' },
        { label: '充值中', value: 'recharge' },
        { label: '充值成功', value: 'recharge_success' },
        { label: '充值失败', value: 'recharge_fail' },
      ],
      form: {
        orderId: '',
        phone: '',
        userPhone: '',
        statusValue: '',
        screenTime: '',
      },
      tableMaxHeight: 300,
      orderList: [],
      page: 1,
      pageSize: 20,
      pageCount: 0,
      orderCount: 0,
      actualAmount: 0,
      rechargeStateList: [
        { labelName: '充值中', labelId: 6 },
        { labelName: '充值成功', labelId: 2 },
      ],
      orderId: '',
      orderType: 'buy-rebate-order',
      orderShow: false,
      active: 0,
      id: '',
    };
  },
  created() {
    this.getStatistics();
    this.getList();
  },
  mounted() {
    // this.$nextTick(() => {
    //   if (this.orderShow) {
    //     const height = document.getElementById('order-list').offsetHeight;
    //     this.tableMaxHeight = height - 250;
    //     console.log(this.tableMaxHeight);
    //   }
    // });
  },
  methods: {
    openRefuseOrder(id) {
      this.$refs.refuseOrder.dialogs = {
        title: '提示',
        content: '确认退款？',
      };
      this.$refs.refuseOrder.showDialog = true;
      this.id = id;
    },
    sureRefuse() {
      $sg_passRefuse(this.id).then(() => {
        this.$refs.refuseOrder.showDialog = false;
        this.getList();
        this.getStatistics();
      });
    },
    cancelRefuse() {
      this.$refs.refuseOrder.showDialog = false;
    },
    // 导出文件
    phoneOrderExport() {
      const startTime = this.form.screenTime && this.form.screenTime.length > 0
        ? this.$formatDateByDate(this.form.screenTime[0], 'yyyy-MM-dd')
        : '';
      const endTime = this.form.screenTime && this.form.screenTime.length > 0
        ? this.$formatDateByDate(this.form.screenTime[1], 'yyyy-MM-dd')
        : '';
      const params = {
        startTime,
        endTime,
        orderId: this.form.orderId,
        phone: this.form.phone,
        platfromState: this.form.statusValue,
        tel: this.form.userPhone,
        pageCurrent: this.page,
        pageSize: this.pageSize,
      };
      exportFileSync(
        '/api/user/biz/chargeOrder/platfrom/export',
        {
          ...params,
        },
        'get',
        '花费充值订单.xlsx',
        this.onDownloadProgress,
      ).then(() => {
        // this.handleDownloadQrCodeLeftBtn();
      });
    },
    selectOrderType(index) {
      console.log(index);
      this.active = index;
      if (index === 0) {
        this.orderType = 'buy-rebate-order';
        this.orderShow = false;
      } else if (index === 1) {
        this.orderType = 'buy-rebate-card-order';
        this.orderShow = false;
      } else if (index === 3) {
        this.orderType = 'buy-coupon-order';
        this.orderShow = false;
      } else if (index === 4) {
        this.orderType = 'buy-ssGroup-order';
        this.orderShow = false;
      } else {
        this.orderType = '';
        this.orderShow = true;
      }
    },
    getTime(time) {
      console.log('查看选中时间', this.form, time);
    },
    screen() {
      const reg = /^1[3456789]\d{9}$/;
      if ((this.form.phone && !reg.test(this.form.phone)) || (this.form.userPhone && !reg.test(this.form.userPhone))) {
        this.$message.error('请输入正确电话号码');
        return;
      }
      this.page = 1;
      this.getList();
      this.getStatistics();
    },
    clear() {
      this.form = {
        orderId: '',
        phone: '',
        userPhone: '',
        statusValue: '',
        screenTime: '',
      };
      this.screen();
    },
    getStatistics() {
      const startTime = this.form.screenTime && this.form.screenTime.length > 0
        ? this.$formatDateByDate(this.form.screenTime[0], 'yyyy-MM-dd')
        : '';
      const endTime = this.form.screenTime && this.form.screenTime.length > 0
        ? this.$formatDateByDate(this.form.screenTime[1], 'yyyy-MM-dd')
        : '';
      const params = {
        startTime,
        endTime,
      };
      $sg_getRebateStatistics(params).then((res) => {
        this.orderCount = res.orderCount || 0;
        this.actualAmount = res.actualAmount || 0;
      });
    },
    getList() {
      const startTime = this.form.screenTime && this.form.screenTime.length > 0
        ? this.$formatDateByDate(this.form.screenTime[0], 'yyyy-MM-dd')
        : '';
      const endTime = this.form.screenTime && this.form.screenTime.length > 0
        ? this.$formatDateByDate(this.form.screenTime[1], 'yyyy-MM-dd')
        : '';
      const params = {
        startTime,
        endTime,
        orderId: this.form.orderId,
        phone: this.form.phone,
        platfromState: this.form.statusValue,
        tel: this.form.userPhone,
        pageCurrent: this.page,
        pageSize: this.pageSize,
      };
      $sg_getRebateOrderList(params).then((res) => {
        res.records.forEach((item) => {
          item.selectedState = item.state;
        });
        this.orderList = res.records;
        this.pageCount = res.pages;
      });
    },
    passOrder(row) {
      this.$refs.changePage.dialogs = {
        title: '提示',
        content: '确认修改充值状态为充值成功？',
      };
      this.currentItem = row;
      this.$refs.changePage.showDialog = true;
      this.orderId = row.orderId;
    },
    sure() {
      this.currentItem.state = 2;
      $sg_passRebateOrder(this.orderId).then(() => {
      }).finally(() => {
        this.$refs.changePage.showDialog = false;
        this.orderId = '';
        this.getList();
      });
    },
    cancel() {
      this.currentItem.selectedState = 6;
      this.$refs.changePage.showDialog = false;
    },
  },
  filters: {
    orderState(state) {
      const arrConfig = ['', '未支付', '支付成功', '支付失败', '已退款', '退款失败'];
      return arrConfig[state] || '';
    },
    rechargeState(state) {
      const arrConfig = ['', '充值中', '充值成功', '充值失败', '充值失败', '已关闭'];
      return arrConfig[state] || '';
    },
  },

};
</script>

<style scoped>
.rebate-order-container-page {
  width: 100%;
  /* height: calc(100vh - 82px); */
  /* min-width: 1400px; */
  overflow: hidden;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
}
.order-choice-div {
  margin: 0 0 30px 0;
}
.order-statistics-data-div {
  width: 100%;
  overflow: hidden;
  margin: 0 0 30px 0;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f0f0f0;
  font-size: 14px;
  color: #555;
}
.order-statistics-all-num,
.order-statistics-user-payment {
  margin-right: 80px;
}
.order-list {
  width: 100%;
  /* height:100%; */
  overflow: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
