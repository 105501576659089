<template>
  <div class="eatInOrder">
    <div class="timeChoiceDiv flexbox flex-lr flex-align-center">
        <div class="timeAndBtn flexbox flex-lr flex-align-center mr20">
          <el-input v-model="form.orderId" placeholder="请输入订单编号" style="width:160px;" clearable type="number"></el-input>
          <el-select v-model="form.verificationStatus" placeholder="状态" clearable class="ml20">
            <el-option v-for="item in payStatusList" :key="item.value"
                       :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="form.agentId" placeholder="请选择归属代理商" clearable filterable remote class="ml20"
          :loading="loading" :remote-method="searchAgent" @change="agentSidChange">
            <el-option v-for="item in agentList" :key="item.value"
                       :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-date-picker
            v-model="time"
            class="ml20"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format='yyyy-MM-dd'>
          </el-date-picker>
        </div>
        <el-button type="primary" class="sure" @click="search">筛选</el-button>
        <el-button type="info" class="sure" @click="clear">清空</el-button>
    </div>
    <div class="orderDetail">
      <span>订单总金额:{{totalOrderAmount}}</span>
      <span>订单总笔数:{{total}}</span>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="orderId" label="订单编号" width="200" align="center"></el-table-column>
        <el-table-column prop="goodsName" label="商品名称" align="center"></el-table-column>
        <el-table-column prop="shopName" label="店铺名称" align="center"></el-table-column>
        <el-table-column prop="merchantPhone" label="商户电话" align="center"></el-table-column>
        <el-table-column prop="customerPhone" label="客户电话" align="center"></el-table-column>
        <el-table-column prop="agentName" label="所属代理商" align="center"></el-table-column>
        <!-- <el-table-column prop="platName" label="所属分公司"></el-table-column> -->
        
        <el-table-column prop="orderTimeStr" label="下单时间" width="160" align="center">
          <template slot-scope="scope">
            <!-- <span>{{ $formatDate(scope.row.orderTime, 'yyyy-MM-dd HH:mm:ss') }}</span> -->
            <span>{{scope.row.orderTimeStr}}</span>
          </template>
        </el-table-column>
       <el-table-column prop="verificationStatus" label="状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.verificationStatus == 1">待核销</span>
            <span v-if="scope.row.verificationStatus == 2">已核销</span>
            <span v-if="scope.row.verificationStatus == 3">待审核</span>
             <span v-if="scope.row.verificationStatus == 4">已退款</span>
             <span v-if="scope.row.verificationStatus == 5">退款驳回</span>
             <span v-if="scope.row.verificationStatus == 6">已失效</span>
          </template>
        </el-table-column>
        <el-table-column prop="applyRefundTimeStr" label="申请退款时间" width="160" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.applyRefundTimeStr}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderAmount" label="订单金额(元)" align="center">
          <template slot-scope="scope">{{scope.row.orderAmount || '0'}}</template>
        </el-table-column>
        <!-- <el-table-column prop="refundStatus" label="退款状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.state == 1">待审核</span>
            <span v-else-if="scope.row.state == 2">审核-成功</span>
            <span v-else-if="scope.row.state == 6">审核-失败</span>
            <span v-else>——</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="refusalCause" label="驳回理由" width="140" :show-overflow-tooltip='true' align="center"></el-table-column>
        <el-table-column prop="profit" label="操作" width="150" align="center">
        <!-- <template slot-scope="scope"> -->
            <template slot-scope="scope">
          <el-button type="primary" @click="innerVisibles(scope.row)" v-if="scope.row.verificationStatus == 3">审核</el-button>
        <!-- <el-button type="primary" plain size="mini" @click="check(scope.row)">{{scope.row.applyState !=1?'重新审核':'审核'}}</el-button> -->
        </template>
      </el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage"
                   :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
   <toauditdialog ref="detailPage" :pagedata="pagedata"></toauditdialog>
  </div>
</template>
<script>
import {
  $sp_agentList, $sp_spreadList, $sp_shopInfoList,
  $sp_queryRefundList,
} from '@/api/order';
// import { Toauditdialog } from '@/components/common/aldialog.vue';
import Toauditdialog from '@/components/common/toauditdialog.vue';

export default {
  data() {
    return {
      pagedata: '',
      textareas: '', // 驳回输入框
      innerVisible: false, // 通过弹窗
      innerbouhui: false, // 驳回弹窗
      form: {
        agentId: '', // 代理商
        verificationStatus: 3, // 是否失效
        // refundStatus: '', // 退款状态
        orderId: '', // 订单编号
      },
      time: '',
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      branchOfficeList: [],
      loading: false,
      agentList: [],
      shopList: [],
      // losepayStatusList: [
      //   {
      //     value: 1,
      //     label: '是',
      //   },
      //   {
      //     value: 2,
      //     label: '否',
      //   },
      // ],
      payStatusList: [
        {
          value: 2,
          label: '已核销',
        },
        // {
        //   value: 1,
        //   label: '待核销',
        // },
        {
          value: 6,
          label: '已失效',
        },
        {
          value: 4,
          label: '已退款',
        },
        {
          value: 3,
          label: '待审核',
        },
        {
          value: 5,
          label: '退款驳回',
        },
      ],
      total: 0,
      totalOrderAmount: 0,
    };
  },
  created() {
    this.getTableData();
  },
  mounted() {
    this.getSpreadList();
    this.getAgentList();
    this.getShopList();
    // this.getGroupBuyAmount();
  },
  components: {
    Toauditdialog,
  },
  methods: {
    // 点击驳回
    innerbouhuis() {
      this.innerVisible = false;
      this.innerbouhui = true;
    },
    // 点击确认
    innerVisibleEvt() {
      this.innerVisible = false;
    },
    // 审核
    innerVisibles(item) {
      this.pagedata = item;
      // this.checks = false;
      this.$refs.detailPage.innerVisible = true;
      // this.$refs.detailPage.detailVisible = false;
    },
    // 筛选
    search() {
      this.currentPage = 1;
      this.getTableData();
    },
    // 清空
    clear() {
      this.form = {
        agentId: '', // 代理商
        verificationStatus: '', // 是否失效
        // refundStatus: '', // 退款状态
        orderNo: '', // 订单编号
      };
      this.time = '';
      this.getTableData();
    },
    // 请求统计数据
    // getGroupBuyAmount() {
      
    // },
    // 请求列表数据
    getTableData() {
      const params = {
        pageCurrent: this.currentPage,
        pageSize: 10,
        ...this.form,
      };
      if (this.time) {
        // if (this.time[0].getFullYear() !== this.time[1].getFullYear() || this.time[0].getMonth() !== this.time[1].getMonth()) {
        //   this.$message.error('查询时间必须在同一个月内');
        //   return;
        // }
        // params.orderstartTime = (this.time[0]).getTime() / 1000;
        // params.orderendTime = (this.time[1]).getTime() / 1000 + (24 * 60 * 60 - 1);
        params.orderStartTime = this.time[0];
        params.orderEndTime = this.time[1];
        console.log(params.endTime, 'end,time');
      } else {
        params.orderStartTime = '';
        params.orderEndTime = '';
      }
      // 请求订单数据
      $sp_queryRefundList(params).then((res) => {
        this.tableData = res.records;
        console.log(this.tableData, '我要看数据666666667');
        
        this.totalPage = res.pages;
        this.total = res.total;
        this.totalOrderAmount = res.totalOrderAmount;
      });
      // 请求订单金额和笔数
      // $sg_getGroupBuyAmount(params).then((res) => {
      //   this.orderAmount = res.groupbuyOrderCount;
      //   this.orderNum = res.groupbuyAmount;
      // });
    },
    // 搜索归属分公司
    async searchBranchOffice(query) {
      this.loading = true;
      if (query) {
        await this.getSpreadList(query);
      } else {
        await this.getSpreadList('');
      }
      this.loading = false;
    },
    // 请求归属分公司数据
    getSpreadList(platName = '') {
      const params = {
        platName,
      };
      this.branchOfficeList = [];
      return $sp_spreadList(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.spreadId,
            label: item.platName,
          };
          tempArr.push(json);
        });
        this.branchOfficeList = tempArr;
      });
    },
    // 选择归属分公司
    spreadIdChange() {
      this.form.agentId = '';
      this.form.shopId = '';
      this.getAgentList();
      this.getShopList();
    },
    // 搜索代理商
    async searchAgent(query) {
      this.loading = true;
      if (query) {
        await this.getAgentList(query);
      } else {
        await this.getAgentList('');
      }
      this.loading = false;
    },
    // 请求代理商列表
    getAgentList(agentName = '') {
      const params = {
        spreadId: this.form.spreadId,
        agentName,
      };
      this.agentList = [];
      return $sp_agentList(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.agentSid,
            label: item.agentName,
          };
          tempArr.push(json);
        });
        this.agentList = tempArr;
      });
    },
    // 选择代理商
    agentSidChange() {
      this.form.shopId = '';
      this.getShopList();
    },
    // 搜索商户
    async searchShop(query) {
      this.loading = true;
      if (query) {
        await this.getShopList(query);
      } else {
        await this.getShopList('');
      }
      this.loading = false;
    },
    // 请求商户列表
    getShopList(shopName = '') {
      const params = {
        spreadId: this.form.spreadId,
        agentSid: this.form.agentSid,
        shopName,
      };
      this.shopList = [];
      return $sp_shopInfoList(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.shopId,
            label: item.shopName,
          };
          tempArr.push(json);
        });
        this.shopList = tempArr;
      });
    },
    // 翻页
    pageChange() {
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
.el-tooltip__popper{
     font-size: 15px;
     max-width:30% }
.wei {
    &::before {
      content: "";
      clear: both;
      height: 22px;
      width: 8px;
      display: inline-block;
      border-radius: 5px;
      background: red;
    }
    .remark-title {
    display: inline-block;
    vertical-align: 3px;
    font-size: 22px;
    font-weight: bold;
    padding-left: 5px;
    color: black;
    height: 25px;
  }
  }
.pass {
  text-align: center;
  h3 {
    font-size: 20px;
    color: black;
  }
  .affirm-btn {
    background-color: #ef3f46;
    margin: 50px 0 50px 10px;
    text-align: center;
    width: 100px;
  }
  .cancel-btn {
    background-color: #909399;
    width: 100px;
  }
}
.eatInOrder{
  width: 100%;
  min-width: 1400px;
  height: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .orderDetail{
    width: 100%;
    margin-top: 40px;
    padding:20px;
    box-sizing: border-box;
    background-color: #f0f0f0;
    font-size: 14px;
    color: #555;
    span:first-child{
      margin-right: 80px;
    }
  }
  .tableBox{
    width: 100%;
    margin-top: 40px;
  }
  .el-table{
    min-height: 580px;
    & > th {
      background-color: #EF3F46;
      color: #fff;
    }
  }
}
</style>

