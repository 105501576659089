<template>
  <div class="eatInOrder">
    <div class="timeChoiceDiv flexbox flex-lr flex-align-center">
        <div class="timeAndBtn flexbox flex-lr flex-align-center mr20">
          <el-input v-model="form.orderId" placeholder="请输入订单编号" style="width:160px;" clearable type="number"></el-input>
          <el-select v-model="form.verificationStatus" placeholder="核销状态" clearable class="ml20">
            <el-option v-for="item in payStatusList" :key="item.value"
                       :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <!-- <el-select v-model="form.payState" class="ml20" clearable placeholder="退款状态" style="width:150px;">
        <el-option v-for="item in payMethodList" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select> -->
          <!-- <el-select v-model="form.spreadId" placeholder="是否失效" clearable filterable remote class="ml20"
            :loading="loading" :remote-method="searchBranchOffice" @change="spreadIdChange">
            <el-option v-for="item in branchOfficeList" :key="item.value"
                       :label="item.label" :value="item.value">
            </el-option>
          </el-select> -->
          <!-- <el-select v-model="form.lose" placeholder="是否失效" clearable class="ml20">
            <el-option v-for="item in losepayStatusList" :key="item.value"
                       :label="item.label" :value="item.value">
            </el-option>
          </el-select> -->
          <el-select v-model="form.agentId" placeholder="请选择归属代理商" clearable filterable remote class="ml20"
          :loading="loading" :remote-method="searchAgent" @change="agentSidChange">
            <el-option v-for="item in agentList" :key="item.value"
                       :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-date-picker
            v-model="time"
            class="ml20"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format='yyyy-MM-dd'>
          </el-date-picker>
        </div>
        <el-button type="primary" class="sure" @click="search">筛选</el-button>
        <el-button type="info" class="sure" @click="clear">清空</el-button>
    </div>
    <div class="orderDetail">
      <span>订单总金额:{{totalOrderAmount}}</span>
      <span>订单总笔数:{{total}}</span>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="orderId" label="订单编号" width="200" align="center"></el-table-column>
        <el-table-column prop="channelOrderId" label="渠道订单号" width="200" align="center"></el-table-column>
        <el-table-column prop="shopName" label="店铺名称" align="center"></el-table-column>
        <el-table-column prop="customerPhone" label="客户电话" align="center"></el-table-column>
        <el-table-column prop="agentName" label="所属代理商" align="center"></el-table-column>
        <el-table-column prop="orderTimeStr" label="下单时间" width="160" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.orderTimeStr }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="payMethod" label="支付方式" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.payMethod == 1">会员钱包支付</span>
            <span v-if="scope.row.payMethod == 2">微信支付</span>
            <span v-if="scope.row.payMethod == 3">钱包支付</span>
          </template>
        </el-table-column> -->
       <el-table-column prop="verificationStatus" label="核销状态" align="center">
          <template slot-scope="scope">
             <span v-if="scope.row.verificationStatus == 1">待核销</span>
            <span v-if="scope.row.verificationStatus == 2">已核销</span>
            <span v-if="scope.row.verificationStatus == 3">待审核</span>
             <span v-if="scope.row.verificationStatus == 4">已退款</span>
             <span v-if="scope.row.verificationStatus == 5">退款驳回</span>
             <span v-if="scope.row.verificationStatus == 6">已失效</span>
          </template>
        </el-table-column>
        <el-table-column prop="completeTime" label="分润时间" width="160">
          <template slot-scope="scope" v-if="scope.row.verificationStatus == 2">
            <!-- <span>{{ $formatDate(scope.row.completeTime, 'yyyy-MM-dd HH:mm:ss') }}</span> -->
            <span>{{scope.row.completeTimeStr }}</span>
          </template>
          <template slot-scope="scope" v-else-if="scope.row.verificationStatus == 6">
            <span>{{scope.row.expiredTimeStr }}</span>
           </template>
        </el-table-column>
        <el-table-column prop="orderAmount" label="订单金额(元)" align="center">
          <template slot-scope="scope">{{scope.row.orderAmount || '0'}}</template>
        </el-table-column>
        <el-table-column prop="agentEarnings" label="代理商收益(元)" align="center">
           <template slot-scope="scope">{{scope.row.agentEarnings || '0'}}</template>
        </el-table-column>
        <el-table-column prop="platEarnings" label="平台收益(元)" align="center">
          <template slot-scope="scope">{{scope.row.platEarnings || '0'}}</template>
        </el-table-column>
        <el-table-column prop="merchantEarnings" label="商户收益(元)" align="center">
          <template slot-scope="scope">{{scope.row.merchantEarnings || '0'}}</template>
        </el-table-column>
        <el-table-column prop="userEarnings" label="用户收益(元)" align="center">
          <template slot-scope="scope">{{scope.row.userEarnings || '0'}}</template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage"
                   :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
  </div>
</template>
<script>
import {
  $sp_agentList, $sp_spreadList, $sp_shopInfoList,
  $sp_queryList,
} from '@/api/order';

export default {
  data() {
    return {
      form: {
        // groupOrderId: '',
        // spreadId: '',
        // agentId: '',
        // state: '',
        // payState: '',
        orderId: '', // 订单编号
        verificationStatus: '', // 核销状态
        agentId: '', // 归属代理商

      },
      verificationStatus: '', // 核销状态
      time: '',
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      branchOfficeList: [],
      loading: false,
      agentList: [],
      shopList: [],
      losepayStatusList: [
        {
          value: 1,
          label: '是',
        },
        {
          value: 2,
          label: '否',
        },
      ],
      payStatusList: [
        {
          value: 2,
          label: '已核销',
        },
        {
          value: 1,
          label: '待核销',
        },
        {
          value: 6,
          label: '已失效',
        },
        {
          value: 4,
          label: '已退款',
        },
        {
          value: 3,
          label: '待审核',
        },
        {
          value: 5,
          label: '退款驳回',
        },
      ],
      total: 0,
      totalOrderAmount: 0,
    };
  },
  created() {
    this.getTableData();
  },
  mounted() {
    this.getSpreadList();
    this.getAgentList();
    this.getShopList();
    // this.getGroupBuyAmount();
  },
  methods: {
    // 筛选
    search() {
      this.currentPage = 1;
      this.getTableData();
    },
    // 清空
    clear() {
      this.form = {
        orderNo: '', // 订单编号
        verificationStatus: '', // 核销状态
        agentId: '', // 归属代理商
      };
      this.time = '';
      this.getTableData();
    },
    // 请求统计数据
    // getGroupBuyAmount() {
      
    // },
    // 请求列表数据
    getTableData() {
      const params = {
        pageCurrent: this.currentPage,
        pageSize: 10,
        ...this.form,
      };
      if (this.time) {
        // if (this.time[0].getFullYear() !== this.time[1].getFullYear() || this.time[0].getMonth() !== this.time[1].getMonth()) {
        //   this.$message.error('查询时间必须在同一个月内');
        //   return;
        // }
        // params.orderstartTime = (this.time[0]).getTime() / 1000;
        // params.orderendTime = (this.time[1]).getTime() / 1000 + (24 * 60 * 60 - 1);
        params.orderStartTime = this.time[0];
        params.orderEndTime = this.time[1];
        console.log(params.endTime, 'end,time');
      } else {
        params.orderstartTime = '';
        params.orderendTime = '';
      }
      // 请求订单数据
      $sp_queryList(params).then((res) => {
        this.tableData = res.records;
        console.log(this.tableData, 'sssssssssssss');
        this.totalPage = res.pages;
        this.total = res.total;
        this.totalOrderAmount = res.totalOrderAmount;
      });
      // 请求订单金额和笔数
      // $sg_getGroupBuyAmount(params).then((res) => {
      //   this.orderAmount = res.groupbuyOrderCount;
      //   this.orderNum = res.groupbuyAmount;
      // });
    },
    // 搜索归属分公司
    async searchBranchOffice(query) {
      this.loading = true;
      if (query) {
        await this.getSpreadList(query);
      } else {
        await this.getSpreadList('');
      }
      this.loading = false;
    },
    // 请求归属分公司数据
    getSpreadList(platName = '') {
      const params = {
        platName,
      };
      this.branchOfficeList = [];
      return $sp_spreadList(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.spreadId,
            label: item.platName,
          };
          tempArr.push(json);
        });
        this.branchOfficeList = tempArr;
      });
    },
    // 选择归属分公司
    spreadIdChange() {
      this.form.agentId = '';
      this.form.shopId = '';
      this.getAgentList();
      this.getShopList();
    },
    // 搜索代理商
    async searchAgent(query) {
      this.loading = true;
      if (query) {
        await this.getAgentList(query);
      } else {
        await this.getAgentList('');
      }
      this.loading = false;
    },
    // 请求代理商列表
    getAgentList(agentName = '') {
      const params = {
        spreadId: this.form.spreadId,
        agentName,
      };
      this.agentList = [];
      return $sp_agentList(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.agentSid,
            label: item.agentName,
          };
          tempArr.push(json);
        });
        this.agentList = tempArr;
      });
    },
    // 选择代理商
    agentSidChange() {
      this.form.shopId = '';
      this.getShopList();
      this.getAgentList();
    },
    // 搜索商户
    async searchShop(query) {
      this.loading = true;
      if (query) {
        await this.getShopList(query);
      } else {
        await this.getShopList('');
      }
      this.loading = false;
    },
    // 请求商户列表
    getShopList(shopName = '') {
      const params = {
        spreadId: this.form.spreadId,
        agentSid: this.form.agentSid,
        shopName,
      };
      this.shopList = [];
      return $sp_shopInfoList(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.shopId,
            label: item.shopName,
          };
          tempArr.push(json);
        });
        this.shopList = tempArr;
      });
    },
    // 翻页
    pageChange() {
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
.eatInOrder{
  width: 100%;
  min-width: 1400px;
  height: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .orderDetail{
    width: 100%;
    margin-top: 40px;
    padding:20px;
    box-sizing: border-box;
    background-color: #f0f0f0;
    font-size: 14px;
    color: #555;
    span:first-child{
      margin-right: 80px;
    }
  }
  .tableBox{
    width: 100%;
    margin-top: 40px;
  }
  .el-table{
    min-height: 520px;
    & > th {
      background-color: #EF3F46;
      color: #fff;
    }
  }
}
</style>

